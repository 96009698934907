import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUser } from '../../App';
import requestClient from '../../utils/requestClient';
import { pillarMapper } from '../../utils/pillarMapper';

import { PurpleRelationshipsIcon } from '../../components/Icons';

const HelpAndSupport = () => {
    const user = useContext(CurrentUser)
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/helpandsupport/')

            let categories = {}

            response.data.data.useful_links.map(links => {
                if (!(links.pillar in categories)) {
                    categories[links.pillar] = []
                }
                categories[links.pillar].push(links)
            })

            setData(categories);
        }
        getData();
    }, []);

    return (user ? 
        <main>
            <section className='bg-white'>
                <div className='container py-8 lg:py-12'>
                    <Link to="/dashboard" className="flex items-center mb-8 lg:hidden">← DASHBOARD</Link>
                    <div className='grid lg:grid-cols-12'>
                        <div className='lg:col-span-8'>
                            <h1 className='mb-6'>Help and support</h1>
                            <div className='prose'>
                                <h2>Wellbeing Support line</h2>
                                <p>
                                    The Wellbeing Support Line provides free, confidential and impartial Practical Advice and Counselling Support. 
                                    This service is free for you to access whenever you need to with no need for permission from your organisation.
                                </p>
                                <p>
                                    It’s simple, just click on the Wellbeing Line button in the OptiMe app to discuss anything that is troubling you.
                                </p>
                            </div>
                        </div>
                        <div className='lg:col-span-4 text-center'>
                            <PurpleRelationshipsIcon className="mx-auto" />
                            {user.user.company.aep_phone_number ?
                                (<Link to={`tel:${user.user.company.aep_phone_number}`} className="btn btn-purple block lg:inline-block mt-6">
                                    {user.user.company.aep_link_name ? user.user.company.aep_link_name : 'CALL A COUNSELOR'}
                                </Link>)
                                :
                                (<Link to="tel:+443332128303" className="btn btn-purple block lg:inline-block mt-6">
                                    CALL A COUNSELOR
                                </Link>)}
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container py-8 lg:py-12'>
                    <div className='mb-8'>
                        <h2 className='mb-2'>Useful links</h2>
                        <p>Help and support from a range of resources which is recommended by OptiMe!</p>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2">
                        {Object.keys(data).map((category) => {
                            return (
                                <div key={category} className="bg-white flex items-center p-8 gap-4 lg:gap-8 flex-col lg:flex-row text-center lg:text-left">
                                    {pillarMapper(category).icon("mx-auto w-full max-w-[80px]")}
                                    <div className='flex-1'>
                                        <h3 className='mb-2 capitalize'>{pillarMapper(category).title}</h3>
                                        <ul className='grid gap-1 lg:grid-cols-2'>
                                            {data[category].map((category, index) => (
                                                <li key={index}>
                                                    <a href={category.link} target="_blank">
                                                        {category.title}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </main>
    : null);
};

export default HelpAndSupport;
